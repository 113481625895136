<nav
  id="navbar-main"
  class="navbar navbar-horizontal navbar-transparent navbar-main navbar-expand-lg navbar-light"
>
  <div class="container">
    <a class="navbar-brand">
      <img src="assets/img/ieeg/logoBlanco.png" style="
      width: 100px;
      height: 50px;"/>
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbar-collapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="navbar-collapse navbar-custom-collapse collapse"
      id="collapseBasic" [collapse]="isCollapsed"
    >
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a >
              <img src="assets/img/ieeg/logoColor.png" />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbar-collapse"
              aria-controls="navbar-collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              (click)="isCollapsed = !isCollapsed"
            >
              <span></span> <span></span>
            </button>
          </div>
        </div>
      </div>

      <hr class="d-lg-none" />
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.facebook.com/IEEGTO/"
            target="_blank"
            data-toggle="tooltip"
            data-original-title="Like us on Facebook"
          >
            <i class="fab fa-facebook-square"></i>
            <span class="nav-link-inner--text d-lg-none">Facebook</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.instagram.com/ieeguanajuato/"
            target="_blank"
            data-toggle="tooltip"
            data-original-title="Follow us on Instagram"
          >
            <i class="fab fa-instagram"></i>
            <span class="nav-link-inner--text d-lg-none">Instagram</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://twitter.com/IEEG"
            target="_blank"
            data-toggle="tooltip"
            data-original-title="Follow us on Twitter"
          >
            <i class="fab fa-twitter-square"></i>
            <span class="nav-link-inner--text d-lg-none">Twitter</span>
          </a>
        </li>

      </ul>
    </div>
  </div>
</nav>
<router-outlet></router-outlet>
<footer class="py-5">
  <div class="container">
    <div class="row align-items-center justify-content-xl-between">
      <div class="col-xl-6">
        <div class="copyright text-center text-xl-left text-muted">
          &copy; {{ test | date: "yyyy" }}
          <a
            href="https://www.ieeg.mx/"
            class="font-weight-bold ml-1"
            target="_blank"
            >IEEG</a
          >
        </div>
      </div>
    </div>
  </div>
</footer>
