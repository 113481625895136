import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  delay,
  map,
  Observable,
  retry,
  Subject,
  tap,
  throwError,
} from 'rxjs';

import { environment } from 'src/environments/environment';
import { registroExpediente } from '../models/registroExpediente';
import { MateriaResponse, Materia } from '../models/MateriaResponse';
import { MencionResponse } from '../models/MencionResponse';
import { DocumentoResponse } from '../models/DocumentoResponse';
import { RecursoResponse } from '../models/RecursoResponse';
import { AdopcionResponse } from '../models/AdopcionResponse';
import { MencionCAResponse } from '../models/MencionCAResponse';
import { IndicacionResponse } from '../models/IndicacionResponse';
import { RecursoCAResponse } from '../models/RecursoCAResponse';
import { RecursoCUResponse } from '../models/RecursoCUResponse';
import { CumplimientoResponse } from '../models/CumplimientoResponse';
import { listaexpediente } from '../models/ListaexpedienteReponse';
import { ReporteActos } from '../models/ReporteActos';
import { DenuncianteResponse } from '../models/DenuncianteResponse';
import { EstatuResponse } from '../models/EstatuResponse';
import { ConsejoResponse } from '../models/ConsejoResponse';
import {
  CargaDocumento,
  DocumentoCargaResponse,
} from '../models/DocumentoCarga';
import { PESResponse } from '../models/PESResponse';
import { Cargo } from '../models/Cargo';
import {
  Actuacion,
  MencionResponseActuacion,
} from '../models/ActuacionResponse';
import { Partes } from '../models/Partes';
import { DenunciasPartidos } from '../models/DenunciasPArtidos';
import { QuejasMateria } from '../models/Graficas/QuejasMateria';
import { MedidasCautelares } from '../models/Graficas/MedidasCautelares';
import { SolicitudMeCau } from '../models/Graficas/SolicitudMeCaut';
import { TotalDenunciantes } from '../models/Graficas/TotalDenunciantes';
import { TotalMedidas } from '../models/Graficas/TotalMedidas';
import { DeclaraIncompetente } from '../models/DeclaraIncompetente';
import { Cumplimientos } from '../models/Cumplimientos';
import { Indicaciones } from '../models/Indicaciones';
import { Materias } from '../models/Materias';
import { Menciones } from '../models/Menciones';
import { MencionesCA } from '../models/MencionesCA';
import { Recursos } from '../models/Recursos';
import { RecursosCA } from '../models/RecursosCA';
import { RecursosCU } from '../models/RecursosCU';
import { SubCategoria } from '../models/SubCategoria';
import { Submatery } from '../models/Graficas/Submatery';
import { registroVencido } from '../models/registroVencido';
import { Reply } from '../models/Reply';

@Injectable({
  providedIn: 'root',
})
export class ExpedienteService {
  private httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',

      encoding: 'UTF-8',
    }),
  };
  private apiResponse(response: Response) {
    let body: any = response;
    return body || {};
  }
  myApiUrl = environment.apiUrl;

  private _refresh$ = new Subject<void>();
  registrosVencidos: registroVencido[];
  private actualizarFormulario = new BehaviorSubject<registroExpediente>(
    {} as any
  );

  constructor(private http: HttpClient) {}

  guardaServicioExpediente(formData) {
    return this.http.post<any>(`${this.myApiUrl}Registro`, formData);
  }

  MostrarTramite(id: number) {
    return this.http.get<any>(`${this.myApiUrl}Registro/GetByTramiteId/${id}`);
  }

  guardaServicioTramite(formData) {
    return this.http.post<any>(`${this.myApiUrl}Registro/Tramites`, formData);
  }

  editaServicioTramite(formData) {
    const httOptions = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
      }),
    };
    return this.http.put<any>(
      `${this.myApiUrl}Registro/UpdateTramites`,
      formData,
      httOptions
    );
  }

  guardaDocumentos(formData): Observable<any> {
    return this.http.post<any>(`${this.myApiUrl}Registro/GuardaDocs`, formData);
  }

  eliminaDocumento(Expediente: CargaDocumento): Observable<CargaDocumento> {
    return this.http.put<CargaDocumento>(
      this.myApiUrl + 'Registro/EliminaDoc',
      Expediente
    );
  }

  getInformacionDetalladaExpedienteByRegistroId(
    idregistro: number
  ): Observable<any> {
    return this.http.get<any>(
      this.myApiUrl +
        'Registro/GetInformacionDetalladaExpedienteByRegistroId/' +
        idregistro
    );
  }

  getActuacionesByRegistroId(idregistro: number): Observable<any> {
    return this.http.get<any>(
      this.myApiUrl + 'Registro/GetActuacionesByRegistroId/' + idregistro
    );
  }

  getDocsCargadosByRegistroId(idregistro: number): Observable<any> {
    return this.http.get<any>(
      this.myApiUrl + 'Registro/GetDocsCargadosByRegistroId/' + idregistro
    );
  }
  getTramitesByRegistroId(idregistro: number): Observable<any> {
    return this.http.get<any>(
      this.myApiUrl + 'Registro/GetTramitesByRegistroId/' + idregistro
    );
  }

  GetExpedienteByRegistroId(idregistro: number): Observable<any> {
    return this.http.get<any>(
      this.myApiUrl + 'Registro/GetExpedienteByRegistroId/' + idregistro
    );
  }
  obtenerExpedientes(): Observable<any> {
    return this.http.get<any>(this.myApiUrl + 'Registro');
  }

  obtenerUnExpediente(idregistro: number): Observable<Reply> {
    return this.http
      .get<Reply>(this.myApiUrl + 'Registro/' + idregistro)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  obtenerDesactualizados(idOrgano, officeLocation): Observable<Reply> {
    return this.http
      .get<Reply>(
        this.myApiUrl + `Registro/PorFecha/` + idOrgano + '/' + officeLocation
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  eliminarExpediente(idregistro: number): Observable<registroExpediente> {
    return this.http.delete<registroExpediente>(
      this.myApiUrl + 'Registro/' + idregistro
    );
  }

  actualizarExpediente(formData) {
    return this.http.put<any>(`${this.myApiUrl}Registro/`, formData);
  }

  actualizarEstatus(idregistro: number, numeroEmpleado: number) {
    return this.http.put<Reply>(
      `${this.myApiUrl}Registro/ConcluirExpediente?registroId=${idregistro}&numeroEmpleado=${numeroEmpleado}`,
      idregistro
    );
  }

  CambiaEstatus(idregistro: number, numeroEmpleado: number) {
    return this.http.put<Reply>(
      `${this.myApiUrl}Registro/CambiaEstatus?registroId=${idregistro}&numeroEmpleado=${numeroEmpleado}`,
      idregistro
    );
  }

  AceptarExpediente(idregistro: number, aceptar: boolean) {
    return this.http.put<any>(
      `${this.myApiUrl}Registro/AceptarExpediente?registroId=${idregistro}&aceptar=${aceptar}`,
      {}
    );
  }

  ListExpedientePaginated(Expediente: listaexpediente): Observable<any> {
    return this.http.post<any>(
      `${this.myApiUrl}Registro/filter-report-pagiated`,
      Expediente,
      this.httpOptions
    );
  }

  ListExpediente(Expediente: listaexpediente): Observable<any> {
    return this.http.post<any>(
      `${this.myApiUrl}Registro/filter-report`,
      Expediente,
      this.httpOptions
    );
  }

  DocsExpediente(idregistro: number): Observable<any> {
    return this.http.get<any>(
      this.myApiUrl + 'Registro/Documentos/' + idregistro
    );
  }

  DeleteDocument(iddocumentoregistro: Number): Observable<any> {
    return this.http.delete<any>(
      this.myApiUrl +
        `Registro/DeleteDocumentoRegistradoId/${iddocumentoregistro}`
    );
  }

  actualizar(expediente) {
    this.actualizarFormulario.next(expediente);
  }

  obtenerExpediente(): Observable<registroExpediente> {
    return this.actualizarFormulario.asObservable();
  }

  //#region ==========> Reposición Expediente <==========
  CreateReposicionExpediente(datos: any): Observable<any> {
    return this.http.post<any>(
      this.myApiUrl + 'reposicionExpediente/CreateReposicionExpediente',
      datos
    );
  }

  ObtReposicionExpedienteByRegistroId(idregistro: number): Observable<any> {
    return this.http.get<any>(
      this.myApiUrl +
        'reposicionExpediente/GetReposicionExpedienteByRegistroId/' +
        idregistro
    );
  }

  UpdateReposicionExpediente(datos: any): Observable<any> {
    return this.http.get<any>(
      this.myApiUrl + 'reposicionExpediente/UpdateReposicionExpediente',
      datos
    );
  }

  //#region ==========> Reasignación Expediente <==========
  CreateReasignacionExpediente(datos: any): Observable<any> {
    return this.http.post<any>(
      this.myApiUrl + 'reasignaciones/CreateReasignacionExpediente',
      datos
    );
  }

  GetReasignacionByRegistroId(idregistro: number): Observable<any> {
    return this.http.get<any>(
      this.myApiUrl + 'reasignaciones/GetReasignacionByRegistroId/' + idregistro
    );
  }

  UpdateReasignacionExpediente(datos: any): Observable<any> {
    return this.http.get<any>(
      this.myApiUrl + 'reasignaciones/UpdateReasignacion',
      datos
    );
  }

  //#region ==========> MATERIAS <==========
  readAllMaterias(): Observable<MateriaResponse> {
    return this.http
      .get<MateriaResponse>(`${this.myApiUrl}materias`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> ESTADOS PROCESALES <==========
  readAllEstadosProcesales(): Observable<Reply> {
    return this.http
      .get<Reply>(`${this.myApiUrl}EstadoProcesal`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> ACTUACIONES <==========
  readAllActuacion(): Observable<MencionResponseActuacion> {
    return this.http
      .get<MencionResponseActuacion>(
        `${this.myApiUrl}catalogos/GetAllTipoActuaciones`
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> MENCIONES <==========
  readAllMenciones(): Observable<MencionResponse> {
    return this.http
      .get<MencionResponse>(`${this.myApiUrl}menciones`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> RECURSOS <==========
  readAllRecursos(): Observable<RecursoResponse> {
    return this.http
      .get<RecursoResponse>(`${this.myApiUrl}recursos`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> DOCUMENTOS <==========
  readAllDocumentos(): Observable<DocumentoResponse> {
    return this.http
      .get<DocumentoResponse>(`${this.myApiUrl}documentos/GetAllDocumentos`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> ADOPCIONES <==========
  readAllAdopciones(): Observable<AdopcionResponse> {
    return this.http
      .get<AdopcionResponse>(`${this.myApiUrl}adopciones`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  getActuacionXId(idActuacion): Observable<any> {
    return this.http.get<any>(
      `${this.myApiUrl}catalogos/GetByTipoActuacionId/` + idActuacion
    );
  }

  //#region ==========> MENCIONESCA <==========
  readAllMencionesCA(): Observable<MencionCAResponse> {
    return this.http
      .get<MencionCAResponse>(`${this.myApiUrl}mencionesCA`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> INIDCACIONES <==========
  readAllIndicaciones(): Observable<IndicacionResponse> {
    return this.http
      .get<IndicacionResponse>(`${this.myApiUrl}indicaciones`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> RECURSOS_CA <==========
  readAllRecursosCA(): Observable<RecursoCAResponse> {
    return this.http
      .get<RecursoCAResponse>(`${this.myApiUrl}recursosCA`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> RECURSOS_CU <==========
  readAllRecursosCU(): Observable<RecursoCUResponse> {
    return this.http
      .get<RecursoCUResponse>(`${this.myApiUrl}recursosCU`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> CUMPLIMIENTOS <==========
  readAllCumplimientos(): Observable<CumplimientoResponse> {
    return this.http
      .get<CumplimientoResponse>(`${this.myApiUrl}cumplimientos`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> ORGANOS <==========
  readAllOrganos(numeroEmpleado: number): Observable<Reply> {
    return this.http
      .get<Reply>(`${this.myApiUrl}organos/${numeroEmpleado}`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> ESTATUS <==========
  readAllEstatus(): Observable<EstatuResponse> {
    return this.http
      .get<EstatuResponse>(`${this.myApiUrl}estatus`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> REPORTE 2 <==========
  totalReportActos(fechaInicio, fechaFin): Observable<ReporteActos> {
    return this.http
      .get<ReporteActos>(
        `${this.myApiUrl}Registro/Obt_Report_Actos/` +
          fechaInicio +
          '/' +
          fechaFin
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> REPORTE DENUNCIANTES <==========
  totalReportDenunciantes(
    fechaInicio,
    fechaFin
  ): Observable<DenuncianteResponse> {
    return this.http
      .get<DenuncianteResponse>(
        `${this.myApiUrl}Registro/Obt_Report_Denunciantes/` +
          fechaInicio +
          '/' +
          fechaFin
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }
  AllSubMateria(idmateria, fechaInicio, fechaFin): Observable<Submatery> {
    return this.http.get<Submatery>(
      `${this.myApiUrl}Registro/sub_category_mat/` +
        idmateria +
        '/' +
        fechaInicio +
        '/' +
        fechaFin
    );
  }

  //#region ==========> REPORTE CONSEJO <==========
  totalReportConsejos(fechaInicio, fechaFin): Observable<ConsejoResponse> {
    return this.http
      .get<ConsejoResponse>(
        `${this.myApiUrl}Registro/Obt_Report_QuejasxConsejo/` +
          fechaInicio +
          '/' +
          fechaFin
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> REPORTE PES <==========
  totalReportPES(): Observable<PESResponse> {
    return this.http
      .get<PESResponse>(`${this.myApiUrl}Registro/Obt_Report_PES`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#region ==========> REPORTE PARTIDOS <==========
  DenunciasPartidos(RangoFechas): Observable<DenunciasPartidos> {
    return this.http
      .get<DenunciasPartidos>(
        `${this.myApiUrl}catalogos/DenunciasPartidos/${RangoFechas.FechaInicio}/${RangoFechas.FechaFinal}/${RangoFechas.Movimiento}`,
        this.httpOptions
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#endregion
  //#region ==========> QUEJAS POR MATERIA <==========
  QuejaMaterias(RangoFechas): Observable<QuejasMateria> {
    return this.http
      .get<QuejasMateria>(
        `${this.myApiUrl}catalogos/QuejasMateria/${RangoFechas.FechaInicio}/${RangoFechas.FechaFinal}/${RangoFechas.Movimiento}`,
        this.httpOptions
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#endregion
  //#region ==========> MEDIDAS CAUTELARES <==========
  MedidasCautelares(RangoFechas): Observable<MedidasCautelares> {
    return this.http
      .get<MedidasCautelares>(
        `${this.myApiUrl}catalogos/MedidasCautelares/${RangoFechas.FechaInicio}/${RangoFechas.FechaFinal}/${RangoFechas.Movimiento}`,
        this.httpOptions
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#endregion
  //#region ==========> SOLICITUD MEDIDAS CAUTELARES <==========
  SolicitudMC(RangoFechas): Observable<SolicitudMeCau> {
    return this.http
      .get<SolicitudMeCau>(
        `${this.myApiUrl}catalogos/SolicitudMC/${RangoFechas.FechaInicio}/${RangoFechas.FechaFinal}/${RangoFechas.Movimiento}`,
        this.httpOptions
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#endregion
  //#region ==========> SOLICITUD MEDIDAS CAUTELARES <==========
  TotalDenunciantes(RangoFechas): Observable<TotalDenunciantes> {
    return this.http
      .get<TotalDenunciantes>(
        `${this.myApiUrl}catalogos/TotalDenunciantes/${RangoFechas.FechaInicio}/${RangoFechas.FechaFinal}/${RangoFechas.Movimiento}`,
        this.httpOptions
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#endregion
  //#region ==========> SOLICITUD MEDIDAS CAUTELARES <==========
  TotalMedidas(RangoFechas): Observable<TotalMedidas> {
    return this.http
      .get<TotalMedidas>(
        `${this.myApiUrl}catalogos/TotalMedidas/${RangoFechas.FechaInicio}/${RangoFechas.FechaFinal}/${RangoFechas.Movimiento}`,
        this.httpOptions
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //#endregion
  FilterFechaRegistro(RangoFechas: any): Observable<any> {
    return this.http
      .post<any>(
        `${this.myApiUrl}Registro/filter-FechaRegistro`,
        RangoFechas,
        this.httpOptions
      )
      .pipe(
        retry(3),
        delay(1000),
        catchError(this.handleError),
        map(this.apiResponse)
      );
  }

  postDocumentos(formData) {
    return this.http
      .post(`${this.myApiUrl}api/documentos/acuse`, formData)
      .pipe(
        map(this.apiResponse),
        tap(() => {
          this._refresh$.next();
        })
      );
  }

  //#endregion

  //-----------------------------------CATALOGOS-------------------------------------//
  //----------------------------------  CARGOS  -------------------------------------//
  getAllTipoPersonas(origenConsulta: string): Observable<Reply> {
    return this.http
      .get<Reply>(
        `${this.myApiUrl}catalogos/GetAllTipoPersonas/${origenConsulta}`
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //-----------------------------------CATALOGOS-------------------------------------//
  //----------------------------------  CARGOS  -------------------------------------//
  getAllCargos(origenConsulta: string): Observable<Reply> {
    return this.http
      .get<Reply>(`${this.myApiUrl}catalogos/GetAllCargos/${origenConsulta}`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  createCargos(cargo: Cargo): Observable<Reply> {
    return this.http
      .post<Reply>(`${this.myApiUrl}catalogos/CreateCargo`, cargo)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  updateCargos(cargo: Cargo): Observable<Reply> {
    return this.http
      .put<Reply>(`${this.myApiUrl}catalogos/UpdateCargo`, cargo)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //ELIMINAR CARGOS
  deleteCargos(cargoId: number, numeroEmpleado: number): Observable<Reply> {
    return this.http
      .delete<Reply>(`${this.myApiUrl}catalogos/DeleteCargo/${cargoId}/${numeroEmpleado}`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //---------------------------------  FIN CARGOS  ------------------------------------//
  //OBTENER TIPO ACTUACIONES
  getAllTipoActuaciones(origenConsulta: string): Observable<Reply> {
    return this.http
      .get<Reply>(
        `${this.myApiUrl}catalogos/GetAllTipoActuaciones/${origenConsulta}`
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //CREAR TIPO ACTUACIONES
  createTipoActuaciones(actuacion: Actuacion): Observable<Reply> {
    return this.http
      .post<Reply>(`${this.myApiUrl}catalogos/CreateTipoActuacion`, actuacion)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //ACTUALIZAR TIPO ACTUACIONES
  updateTipoActuaciones(actuacion: Actuacion): Observable<Reply> {
    return this.http
      .put<Reply>(`${this.myApiUrl}catalogos/UpdateTipoActuacion`, actuacion)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //ELIMINAR TIPO ATUACIONES
  deleteTipoActuaciones(tipoActuacionId: number, numeroEmpleado: number): Observable<Reply> {
    return this.http
      .delete<Reply>(
        `${this.myApiUrl}catalogos/DeleteTipoActuacion/${tipoActuacionId}/${numeroEmpleado}`
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //OBTENER PARTES
  getAllPartes(origenConsulta: string): Observable<Partes[]> {
    return this.http
      .get<Partes[]>(`${this.myApiUrl}catalogos/GetAllPartes/${origenConsulta}`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //REGRESA LA INFORMACION => ID, EXPEDIENTE DE OprRegistros
  getAllExpedientes(idadopcion): Observable<any> {
    return this.http
      .get<any[]>(`${this.myApiUrl}Registro/GetAllExpedientes/${idadopcion}`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  getPartes(origenConsulta): Observable<Reply> {
    return this.http
      .get<Reply>(`${this.myApiUrl}catalogos/GetPartes/${origenConsulta}`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //CREAR PARTES
  createPartes(parte: Partes): Observable<Reply> {
    return this.http
      .post<Reply>(`${this.myApiUrl}catalogos/CreateParte`, parte)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //ACTUALIZAR PARTES
  updatePartes(parte: Partes): Observable<Reply> {
    return this.http
      .put<Reply>(`${this.myApiUrl}catalogos/UpdateParte`, parte)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //ELIMINAR PARTES
  deletePartes(parteId: number, numeroEmpleado: number): Observable<Reply> {
    return this.http
      .delete<Reply>(`${this.myApiUrl}catalogos/DeleteParte/${parteId}/${numeroEmpleado}`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  // CATALOGO DE CUMPLIMIENTO
  //OBTENER CUMPLIMIENTOS
  getAllCumplimientos(origenConsulta: string): Observable<Reply> {
    return this.http
      .get<Reply>(
        `${this.myApiUrl}cumplimientos/GetAllCumplimientos/${origenConsulta}`
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //CREAR CUMPLIMIENTOS
  createCumplimientos(cumplimiento: Cumplimientos): Observable<Reply> {
    return this.http
      .post<Reply>(
        `${this.myApiUrl}cumplimientos/CreateCumplimiento`,
        cumplimiento
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //ACTUALIZAR CUMPLIMIENTOS
  updateCumplimientos(cumplimiento: Cumplimientos): Observable<Reply> {
    return this.http
      .put<Reply>(
        `${this.myApiUrl}cumplimientos/UpdateCumplimiento`,
        cumplimiento
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //DELETE CUMPLIMIENTOS
  deleteCumplimientos(ccumplimiento: number, numeroEmpleado: number): Observable<Reply> {
    return this.http
      .delete<Reply>(
        `${this.myApiUrl}cumplimientos/DeleteCumplimiento/${ccumplimiento}/${numeroEmpleado}`
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  // CATALOGO DE CUMPLIMIENTO
  //OBTENER INDICACIONES
  getAllIndicaciones(origenConsulta: string): Observable<Reply> {
    return this.http
      .get<Reply>(
        `${this.myApiUrl}indicaciones/GetAllIndicaciones/${origenConsulta}`
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //CREAR INDICACIONES
  createIndicaciones(indicacion: Indicaciones): Observable<Reply> {
    return this.http
      .post<Reply>(`${this.myApiUrl}indicaciones/CreateIndicacion`, indicacion)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //ACTUALIZAR INDICACIONES
  updateIndicaciones(indicacion: Indicaciones): Observable<Reply> {
    return this.http
      .put<Reply>(`${this.myApiUrl}indicaciones/UpdateIndicacion`, indicacion)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //DELETE INDICACIONES
  deleteIndicaciones(indicacionId: number, numeroEmpleado: number): Observable<Reply> {
    return this.http
      .delete<Reply>(
        `${this.myApiUrl}indicaciones/DeleteIndicacion/${indicacionId}/${numeroEmpleado}`
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  // CATALOGO DE MATERIAS
  //OBTENER MATERIAS
  getAllMaterias(origenConsulta: string): Observable<Reply> {
    return this.http
      .get<Reply>(`${this.myApiUrl}materias/GetAllMaterias/${origenConsulta}`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //CREAR MATERIAS
  createMaterias(materia: Materia): Observable<Reply> {
    return this.http
      .post<Reply>(`${this.myApiUrl}materias/CreateMateria`, materia)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //ACTUALIZAR MATERIAS
  updateMaterias(materia: Materias): Observable<Reply> {
    return this.http
      .put<Reply>(`${this.myApiUrl}materias/UpdateMateria`, materia)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //DELETE MATERIAS
  deleteMaterias(materiaId: number, numeroEmpleado: number): Observable<Reply> {
    return this.http
      .delete<Reply>(`${this.myApiUrl}materias/DeleteMateria/${materiaId}/${numeroEmpleado}`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  // CATALOGO DE MENCIONES
  //OBTENER MENCIONES
  getAllMenciones(origenConsulta: string): Observable<Reply> {
    return this.http
      .get<Reply>(`${this.myApiUrl}menciones/GetAllMenciones/${origenConsulta}`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //CREAR MENCIONES
  createMenciones(mencion: Menciones): Observable<Reply> {
    return this.http
      .post<Reply>(`${this.myApiUrl}menciones/CreateMencion`, mencion)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //ACTUALIZAR MENCIONES
  updateMenciones(mencion: Menciones): Observable<Reply> {
    return this.http
      .put<Reply>(`${this.myApiUrl}menciones/UpdateMencion`, mencion)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //DELETE MENCIONES
  deleteMencion(mencionId: number, numeroEmpleado: number): Observable<any> {
    return this.http
      .delete<any>(`${this.myApiUrl}menciones/DeleteMencion/${mencionId}/${numeroEmpleado}`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  // CATALOGO DE MENCIONESCA
  //OBTENER MENCIONESCA
  getAllMencionesCA(origenConsulta: string): Observable<Reply> {
    return this.http
      .get<Reply>(
        `${this.myApiUrl}mencionesCA/GetAllMencionesCA/${origenConsulta}`
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //CREAR MENCIONESCA
  createMencionesCA(mencionCA: MencionesCA): Observable<Reply> {
    return this.http
      .post<Reply>(`${this.myApiUrl}mencionesCA/CreateMencionCA`, mencionCA)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //ACTUALIZAR MENCIONESCA
  updateMencionesCA(mencionCA: MencionesCA): Observable<Reply> {
    return this.http
      .put<Reply>(`${this.myApiUrl}mencionesCA/UpdateMencionCA`, mencionCA)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //DELETE MENCIONESCA
  deleteMencionCA(mencionCAId: number, numeroEmpleado: number): Observable<any> {
    return this.http
      .delete<any>(`${this.myApiUrl}mencionesCA/DeleteMencionCA/${mencionCAId}/${numeroEmpleado}`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  // CATALOGO DE RECURSOS
  //OBTENER RECURSOS
  getAllRecursos(origenConsulta: string): Observable<Reply> {
    return this.http
      .get<Reply>(`${this.myApiUrl}recursos/GetAllRecursos/${origenConsulta}`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //CREAR RECURSOS
  createRecursos(recurso: Recursos): Observable<Reply> {
    return this.http
      .post<Reply>(`${this.myApiUrl}recursos/CreateRecurso`, recurso)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //ACTUALIZAR RECURSOS
  updateRecursos(recurso: Recursos): Observable<Reply> {
    return this.http
      .put<Reply>(`${this.myApiUrl}recursos/UpdateRecurso`, recurso)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //DELETE RECURSOS
  deleteRecursos(recursoId: number, numeroEmpleado: number): Observable<any> {
    return this.http
      .delete<any>(`${this.myApiUrl}recursos/DeleteRecurso/${recursoId}/${numeroEmpleado}`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  // CATALOGO DE RECURSOSCA
  //OBTENER RECURSOSCA
  getAllRecursosCA(origenConsulta: string): Observable<Reply> {
    return this.http
      .get<Reply>(
        `${this.myApiUrl}recursosCA/GetAllRecursosCA/${origenConsulta}`
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //CREAR RECURSOSCA
  createRecursosCA(recursoCA: RecursosCA): Observable<Reply> {
    return this.http
      .post<Reply>(`${this.myApiUrl}recursosCA/CreateRecursoCA`, recursoCA)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //ACTUALIZAR RECURSOSCA
  updateRecursosCA(recursoCA: RecursosCA): Observable<Reply> {
    return this.http
      .put<Reply>(`${this.myApiUrl}recursosCA/UpdateRecursoCA`, recursoCA)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //DELETE RECURSOSCA
  deleteRecursosCA(recursoCAId: number, numeroEmpleado: number): Observable<any> {
    return this.http.delete<any>(
      `${this.myApiUrl}recursosCA/DeleteRecursoCA/${recursoCAId}/${numeroEmpleado}`
    );
  }

  // CATALOGO DE RECURSOSCU
  //OBTENER RECURSOSCU
  getAllRecursosCU(): Observable<RecursosCU[]> {
    return this.http
      .get<RecursosCU[]>(`${this.myApiUrl}recursosCU/GetAllRecursosCU`)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //CREAR RECURSOSCU
  createRecursosCU(recursoCU: RecursosCU): Observable<RecursosCU> {
    return this.http.post<RecursosCU>(
      `${this.myApiUrl}recursosCU/CreateRecursoCU`,
      recursoCU
    );
  }

  //ACTUALIZAR RECURSOSCU
  updateRecursosCU(recursoCU: RecursosCU): Observable<RecursosCU> {
    return this.http.put<RecursosCU>(
      `${this.myApiUrl}recursosCU/UpdateRecursoCU`,
      recursoCU
    );
  }

  //DELETE RECURSOSCU
  deleteRecursosCU(recursoCUId: number): Observable<any> {
    return this.http.delete<any>(
      `${this.myApiUrl}recursosCU/DeleteRecursoCU/${recursoCUId}`
    );
  }

  // CATALOGO DE SUBCATEGORIA
  //OBTENER SUBCATEGORIA
  getAllSubCategorias(origenConsulta: string): Observable<Reply> {
    return this.http
      .get<Reply>(
        `${this.myApiUrl}subCategorias/GetAllSubCategorias/${origenConsulta}`
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //CREAR SUBCATEGORIA
  createSubCategorias(subcategoria: SubCategoria): Observable<Reply> {
    return this.http
      .post<Reply>(
        `${this.myApiUrl}subCategorias/CreateSubCategoria`,
        subcategoria
      )
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //ACTUALIZAR SUBCATEGORIA
  updateSubCategorias(subcategoria: SubCategoria): Observable<Reply> {
    return this.http.put<Reply>(`${this.myApiUrl}subCategorias/UpdateSubCategoria`, subcategoria)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

  //DELETE SUBCATEGORIA
  deleteSubCategoria(subCategoriaId: number, numeroEmpleado: number): Observable<any> {
    return this.http.delete<any>(
      `${this.myApiUrl}subCategorias/DeleteSubCategoria/${subCategoriaId}/${numeroEmpleado}`
    );
  }

  handleError(error) {
    let statusError = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      statusError = error.status;
    } else {
      // server-side error
      statusError = error.status;
    }
    return throwError(() => new Error(statusError));
  }

  getUrlFirmada(filePath) {
    return this.http.get<any>(
      `${this.myApiUrl}documentos/GeneratePreSignedURL?filePath=${filePath}`
    );
  }

  // Genera una URL pre-firmada para la carga de archivos a S3.
  getUrlSignedToUploadFiles(fileName: string) {
    return this.http.get<{ url: string }>(`${this.myApiUrl}documentos/GeneratePreSignedPutURL?fileName=${fileName}`);
  }

  uploadFileToS3(url: string, file: File) {
    return this.http.put(url, file, {
      headers: {
        'Content-Type': file.type
      },
      reportProgress: true,
      observe: 'events'
    });
  }

  CreateBitacoraAccesoUsuario(HistorialGetDTO): Observable<Reply> {
    return this.http
      .post<Reply>(`${this.myApiUrl}bitacora-siquede/acceso-usuario`, HistorialGetDTO)
      .pipe(retry(3), delay(1000), catchError(this.handleError));
  }

}