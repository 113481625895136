import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { esLocale } from 'ngx-bootstrap/locale';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { TagInputModule } from 'ngx-chips';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MicrosoftAuthService } from './pages/auth/services/microsoft-auth.service';
import { ActuacionAddComponent } from './pages/emision-expedientes/crear-expediente/actuacion-add/actuacion-add.component';
import { AuthLayoutModule } from './layouts/auth-layout/auth-layout.module';
import { DatePipe } from '@angular/common';

// import { NgxUiLoaderConfig, NgxUiLoaderModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader'

defineLocale('es', esLocale);

//Azure
// import { CrearExpedienteComponent } from "./pages/emision-expedientes/crear-expediente/crear-expediente.component";

const _apiUrl = environment.apiUrl;

//Azure variables
const _redirectAplicationAzure = environment.redirectAplicationAzure;
const _scopesAzure = environment.scopesAzure;
const _clientIdAzure = environment.clientIdAzure;
const _authorityAzure = environment.authorityAzure;
const _me = environment.meAzure;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: _clientIdAzure,
      authority: _authorityAzure,
      redirectUri: _redirectAplicationAzure,
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read', 'mail.read']);
  protectedResourceMap.set(_me, ['user.read']);
  protectedResourceMap.set(_apiUrl, [_scopesAzure]);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    ComponentsModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    AuthLayoutModule,
    ToastrModule.forRoot(),
    CollapseModule.forRoot(),
    TagInputModule,
    BrowserModule,
    ModalModule,

    /*Para validar formulario */
    ReactiveFormsModule,
    /*TABLE PAGINATION*/
    //NgxPaginationModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MicrosoftAuthService,
    BsModalService,
    DatePipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [ActuacionAddComponent],
})
export class AppModule {
  constructor(private bsLocalService: BsLocaleService) {
    this.bsLocalService.use('es');
  }
}
