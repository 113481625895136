<nav class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white" id="sidenav-main"
  (mouseover)="onMouseEnterSidenav()" (mouseout)="onMouseLeaveSidenav()">
  <perfect-scrollbar>
    <div class="scrollbar-inner">
      <div class="sidenav-header d-flex align-items-center">
        <a class="navbar-brand" [routerLink]="['/']">
          <img src="assets/img/ieeg/logoColor.png" class="navbar-brand-img" alt="logoNav" />
        </a>
        <div class="ml-auto">
          <!-- Sidenav toggler -->
          <div class="sidenav-toggler d-none d-xl-block" data-action="sidenav-unpin" data-target="#sidenav-main"
            (click)="minimizeSidebar()">
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar-inner">
        <div class="collapse navbar-collapse" id="sidenav-collapse-main">
          <!-- Collapse header -->
          <ul class="navbar-nav">
            <ng-container *ngFor="let menuitem of menuItems; let i = index">
              <li class="nav-item" *ngIf="checkMenuVisibility(menuitem)">
                <a *ngIf="menuitem.type === 'link'" routerLinkActive="active" [routerLink]="[menuitem.path]"
                  class="nav-link">
                  <i class="{{ menuitem.iconType }}"></i>
                  <span class="nav-link-text">{{ menuitem.title }}</span>
                </a>
                <ng-container *ngIf="menuitem.type === 'sub' && checkMenuVisibility(menuitem)">
                  <a data-toggle="collapse" routerLinkActive="active" class="nav-link"
                    (click)="menuitem.isCollapsed = !menuitem.isCollapsed" [attr.aria-expanded]="!menuitem.isCollapsed"
                    [attr.aria-controls]="getCollapseId(menuitem)">
                    <i class="{{ menuitem.iconType }}"></i>
                    <span class="nav-link-text">{{ menuitem.title }}</span>
                  </a>
                  <div [collapse]="menuitem.isCollapsed" [isAnimated]="true" 
                    [attr.aria-controls]="getCollapseId(menuitem)">
                    <app-submenu [subItems]="menuitem.subItems" [rolesUsuario]="rolesUsuario"></app-submenu>
                  </div>
                </ng-container>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</nav>