import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SesionUsuario } from 'src/app/pages/auth/interfaces/SesionUsuario.interface';
import { MenuItem } from './interface/MenuItem.interface';
import { checkMenuVisibility } from './functions/CheckMenuVisibility.function';
import { getCollapseId } from './functions/GetCollapseId.function';
import { MENU_ROUTES } from './constants/MenuRoutes.const';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public menuItems: MenuItem[] = [];
  public isCollapsed = true;

  public checkMenuVisibility: (menuitem: MenuItem) => boolean =
    checkMenuVisibility;
  public getCollapseId: (menuItem: MenuItem) => string = getCollapseId;

  rolesUsuario: string[] = []; // Asegúrate de que el tipo de datos de rolesUsuario sea correcto

  private selecSubscription: Subscription = new Subscription();
  private routerSubscription: Subscription = new Subscription();

  constructor(private router: Router) {
    // this.selecSubscription = this.store.select(selectorSesionUsuario).subscribe((sesion: SesionUsuario) => {
    //   this.rolesUsuario = JSON.parse(sesion.roles);
    // });

    this.rolesUsuario = JSON.parse(localStorage.getItem('roles'));
  }

  ngOnInit() {
    this.menuItems = this.generateMenuItems(MENU_ROUTES);
    this.routerSubscription = this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
  }

  private generateMenuItems(menuItems: MenuItem[]): MenuItem[] {
    const visibleItems: MenuItem[] = [];

    menuItems.forEach((menuItem) => {
      if (this.checkMenuVisibility(menuItem)) {
        const newMenuItem: MenuItem = { ...menuItem };

        if (menuItem.type === 'sub' && menuItem.subItems) {
          newMenuItem.subItems = this.generateMenuItems(menuItem.subItems);
        }

        visibleItems.push(newMenuItem);
      }
    });

    return visibleItems;
  }

  getNestedCollapseId(menuItem: MenuItem, subMenuItem: MenuItem): string {
    return (
      'collapse-' +
      menuItem.path.replace(/\//g, '-') +
      '-' +
      subMenuItem.path.replace(/\//g, '-')
    );
  }

  onMouseEnterSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  }

  onMouseLeaveSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  }

  minimizeSidebar() {
    const sidenavToggler =
      document.getElementsByClassName('sidenav-toggler')[0];
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('g-sidenav-pinned')) {
      this.isCollapsed = true;
    } else {
      this.isCollapsed = !this.isCollapsed;
    }

    if (this.isCollapsed) {
      body.classList.remove('g-sidenav-pinned');
      body.classList.add('g-sidenav-hidden');
      sidenavToggler.classList.remove('active');
    } else {
      body.classList.add('g-sidenav-pinned');
      body.classList.remove('g-sidenav-hidden');
      sidenavToggler.classList.add('active');
    }
  }

  ngOnDestroy(): void {
    this.selecSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }
}
