import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { allowedUserRol } from 'src/app/components/sidebar/functions/AllowedUserRol.function';

@Injectable({
  providedIn: 'root',
})
export class MicrosoftRolesGuard implements CanActivate {
  constructor(private _router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const userRoles = JSON.parse(localStorage.getItem('roles')) || [];
    const rolesPermitidos = next.data.rolUsuario as string[];

    // Verificar si el usuario tiene algún rol permitido
    const isAllowed = allowedUserRol(userRoles, rolesPermitidos);

    if (!isAllowed) {
      this._router.navigate(['/login']); // Redirigir si no tiene permisos
    }

    return isAllowed;
  }
}
