type optionsUser = { [Key: string]: string };

export const rolesUsuario: optionsUser = {
  SUPERADMIN: 'Usuarios.SuperAdmin',
  ADMINISTRADOR: 'Usuarios.Administrador',
  SUPERVISOR: 'Usuarios.Supervisor',
  CAPTURACONSEJOS: 'Usuarios.ConsejoCaptura',
  CONSULTACONSEJOS: 'Usuarios.ConsejoConsulta',
  CAPTURAJURIDICO: 'Usuarios.UTJCECaptura',
  CONSULTAJURIDICO: 'Usuarios.UTJCEConsulta',
  OFICIALIA: 'Usuarios.Oficialia',
} as const;
