import { rolesUsuario } from './rolesUsers.constant';

type options = { [Key: string]: Readonly<string[]> };

export const permisosMenuUser: options = {
  // MENÚ INICIO
  INICIO: [
    rolesUsuario.SUPERADMIN,
    rolesUsuario.ADMINISTRADOR,
    rolesUsuario.CAPTURAJURIDICO,
    rolesUsuario.CAPTURACONSEJOS,
    rolesUsuario.CONSULTAJURIDICO,
    rolesUsuario.CONSULTACONSEJOS,
    rolesUsuario.OFICIALIA,
  ],

  // MENÚ NUEVO
  NUEVOEXPEDIENTES: [
    rolesUsuario.SUPERADMIN,
    rolesUsuario.ADMINISTRADOR,
    rolesUsuario.CAPTURAJURIDICO,
    rolesUsuario.CAPTURACONSEJOS,
  ],

  // MENÚ BUSCAR
  BUSCAREXPEDIENTES: [
    rolesUsuario.SUPERADMIN,
    rolesUsuario.ADMINISTRADOR,
    rolesUsuario.CAPTURAJURIDICO,
    rolesUsuario.CAPTURACONSEJOS,
    rolesUsuario.CONSULTAJURIDICO,
    rolesUsuario.CONSULTACONSEJOS,
    rolesUsuario.OFICIALIA,
  ],

  // MENÚ REPORTES
  REPORTES: [
    rolesUsuario.SUPERADMIN,
    rolesUsuario.ADMINISTRADOR,
    rolesUsuario.CAPTURAJURIDICO,
  ],
  // MENÚ REPORTES
  MENSAJEREP: [
    rolesUsuario.CAPTURACONSEJOS,
    rolesUsuario.CONSULTACONSEJOS,
    rolesUsuario.CONSULTAJURIDICO,
    rolesUsuario.OFICIALIA,
  ],
  // MENÚ CATALOGO
  MENSAJECATA: [
    rolesUsuario.CAPTURAJURIDICO,
    rolesUsuario.CAPTURACONSEJOS,
    rolesUsuario.CONSULTACONSEJOS,
    rolesUsuario.CONSULTAJURIDICO,
    rolesUsuario.OFICIALIA,
  ],

  // MENÚ CATALOGOS
  CATALOGOS: [rolesUsuario.SUPERADMIN, rolesUsuario.ADMINISTRADOR],
} as const;
