import { permisosMenuUser } from 'src/app/pages/auth/constants/menuUsers.constant';

import { MenuItem } from '../interface/MenuItem.interface';

export const MENU_ROUTES: MenuItem[] = [
  {
    path: '/admin/inicio',
    title: 'Inicio',
    type: 'link',
    iconType: 'fas fa-home text-primary',
    permission: permisosMenuUser.INICIO,
  },
  {
    path: 'expedientes/crearexpediente',
    title: 'Nuevo Expediente',
    type: 'link',
    iconType: 'fas fa-id-card text-primary text-green',
    permission: permisosMenuUser.NUEVOEXPEDIENTES,
  },
  {
    path: 'expedientes/listexpediente',
    title: 'Buscar expediente',
    type: 'link',
    // isCollapsed: true, para que el menu este colapsado y no desplegado esto es para los tipos sub
    iconType: 'fas fa-search text-purple',
    permission: permisosMenuUser.BUSCAREXPEDIENTES,
  },
  {
    path: '/reporeg',
    title: 'Reportes',
    type: 'sub',
    isCollapsed: true,
    iconType: 'fas fa-chart-bar text-red',
    permission: permisosMenuUser.REPORTES,
    subItems: [
      {
        path: '/reporeg/Msg',
        title: 'No eres administrador',
        type: 'link',
        permission: permisosMenuUser.MENSAJEREP,
        // permission: permisosMenuUser.ADMINISTRADOR,
      },
      {
        path: '/reporeg/repreg',
        title: 'Reporte general',
        type: 'link',
        permission: permisosMenuUser.REPORTES,
        // permission: permisosMenuUser.ADMINISTRADOR,
      },
      {
        path: '/reporeg/submat',
        title: 'Materia de la queja o denuncia',
        type: 'link',
        permission: permisosMenuUser.REPORTES,
        // permission: permisosMenuUser.ADMINISTRADOR,
      },
      {
        path: '/reporeg/repact',
        title: 'Reporte de conducta denunciada.',
        type: 'link',
        permission: permisosMenuUser.REPORTES,
      },
      {
        path: '/reporeg/repden',
        title: 'Reporte de denunciantes.',
        type: 'link',
        permission: permisosMenuUser.REPORTES,
      },
      {
        path: '/reporeg/repnum',
        title: 'Reporte por número de quejas por consejo.',
        type: 'link',
        permission: permisosMenuUser.REPORTES,
      },
    ],
  },
  {
    path: '/catalogo',
    title: 'Catálogos',
    type: 'sub',
    isCollapsed: true,
    iconType: 'fas fa-book text-blue',
    permission: permisosMenuUser.CATALOGOS,
    subItems: [
      {
        path: '/catalogo/mensaje',
        title: 'No eres administrador',
        type: 'link',
        permission: permisosMenuUser.MENSAJECATA,
        // permission: permisosMenuUser.REGISTROS,
      },
      {
        path: '/catalogo/cargo',
        title: 'Cargos',
        type: 'link',
        permission: permisosMenuUser.CATALOGOS,
        // permission: permisosMenuUser.REGISTROS,
      },
      {
        path: '/catalogo/partes',
        title: 'Partes',
        type: 'link',
        permission: permisosMenuUser.CATALOGOS,
      },
      {
        path: '/catalogo/tipoActuacion',
        title: 'Tipo de actuación',
        type: 'link',
        permission: permisosMenuUser.CATALOGOS,
      },
      {
        path: '/catalogo/cumplimiento',
        title: 'Cumplimientos',
        type: 'link',
        permission: permisosMenuUser.CATALOGOS,
      },
      {
        path: '/catalogo/indicacion',
        title: 'Indicaciones',
        type: 'link',
        permission: permisosMenuUser.CATALOGOS,
      },
      {
        path: '/catalogo/materia',
        title: 'Materia de la queja o denuncia',
        type: 'link',
        permission: permisosMenuUser.CATALOGOS,
      },
      {
        path: '/catalogo/subcategoria',
        title: 'Conductas denunciadas',
        type: 'link',
        permission: permisosMenuUser.CATALOGOS,
      },
      {
        path: '/catalogo/mencion',
        title: 'Menciones',
        type: 'link',
        permission: permisosMenuUser.CATALOGOS,
      },
      {
        path: '/catalogo/mencionCA',
        title: 'Menciones CA',
        type: 'link',
        permission: permisosMenuUser.CATALOGOS,
      },
      {
        path: '/catalogo/recurso',
        title: 'Recursos',
        type: 'link',
        permission: permisosMenuUser.CATALOGOS,
      },
      {
        path: '/catalogo/recursoCA',
        title: 'Recursos CA',
        type: 'link',
        permission: permisosMenuUser.CATALOGOS,
      },
      // {
      //   path: '/catalogo/recursoCU',
      //   title: 'Recursos CU',
      //   type: 'link',
      //   permission: permisosMenuUser.CATALOGOS,
      // },
    ],
  },
];
