import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService     } from 'ngx-toastr';
import   swal                from 'sweetalert2';

import { MicrosoftAuthService } from '../../services/microsoft-auth.service';
import { ExpedienteService    } from 'src/app/services/expediente.service';
import { environment          } from 'src/environments/environment';
import { Reply                } from 'src/app/models/Reply';

@Component({
  selector   :  'app-login',
  templateUrl:  './login.component.html',
  styleUrls  : ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  entorno: string;
  constructor(
    private microsoftAuthService: MicrosoftAuthService,
    private expedienteService   : ExpedienteService,
    private spinner             : NgxSpinnerService,
    private toastr              : ToastrService,
  ) {}

  ngOnInit(): void {
    this.entorno = environment.nombreEntorno
    this.microsoftAuthService.handleRedirectPromise();
  }

  login() {
    this.microsoftAuthService.login();
    this.microsoftAuthService.msgResponse.subscribe((data) => {
      if (data.error) {
        this.showNotification('danger', 'Error', data.mensaje, 'ban');
      } else {
        const HistorialGetDTO = {
          numberPage: 1,
          idUsuario: data.idUsuario,
        }
        this.expedienteService.CreateBitacoraAccesoUsuario(HistorialGetDTO).subscribe({
          next: ({ result, message }: Reply) => {
            if (result != 1) {
              swal.fire({
                icon : 'warning',
                title: 'Atención:',
                text : message,
                confirmButtonText: 'Aceptar',
              });
            }
          },
          error: (e) => {
            this.handleError(e);
          }
        });
        this.showNotification(
          'success',
          'Inicio de sesión',
          data.mensaje,
          'user-check'
        );
      }
    });
  }

  showNotification(type, titulo, mensaje, icon) {
    if (type === 'danger') {
      type = 'red';
    }

    this.toastr.show(
      `<span class="alert-icon fas fa-${icon}" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">${titulo}</span> <span data-notify="message">${mensaje}</span></div>`,
      '',
      {
        timeOut: 3000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-center',
        toastClass: `ngx-toastr alert alert-dismissible alert-${type} alert-notify`,
      }
    );
  }

  private handleError(error: any) {
    this.spinner.hide();
    let errorMessage = 'No se pudo establecer conexión con el servidor, verifique su conexión.';
    if (error.status === 0) {
      errorMessage = 'Vuelva a intentar por favor';
    } else if (error.status === 400) {
      errorMessage = 'Se produjo uno o más errores de validación.';
    } else if (error.status === 403) {
      errorMessage = 'No cuenta con permisos de acceso para realizar la consulta.';
    }
    swal.fire({
      icon: 'warning',
      title: 'Atención:',
      text: errorMessage,
      confirmButtonText: 'Aceptar',
    });
  }
}
