<div class=" main-content">
  <div class=" header bg-gradient-danger py-7 py-lg-8 pt-lg-9">
    <div class=" container">
      <div class=" header-body text-center mb-7">
        <div class=" row justify-content-center">
          <div class=" col-xl-5 col-lg-6 col-md-8 px-2">
            <h2 class=" text-white">El Instituto Electoral del Estado de Guanajuato (IEEG) te da la bienvenida.</h2>
            <div *ngIf="entorno != 'Produccion'">
              <br>
              <h1 style="color: white; font-weight: bold;">Entorno: {{entorno}}</h1>
            </div>
            <!-- <div>
              <br>
              <h1 style="color: white; font-weight: bold;">Entorno: Pruebas</h1>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="separator separator-bottom separator-skew zindex-100">
      <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </div>

  <div class=" container mt--9 pb-5">
    <div class=" row justify-content-center">
      <div class=" col-lg-5 col-md-7">
        <div class=" card bg-secondary border-0 mb-0">
          <div class=" card-header bg-transparent pb-5">
            <div class=" text-muted text-center mt-2 mb-3">
              <h1 class=" text-black">Iniciar sesión</h1>
              <p class=" text-lead text-black">
                Por favor inicie sesión con su cuenta institucional
              </p>
            </div>

            <div class=" btn-wrapper text-center">
              <img width="45%" src="assets/img/ieeg/logoColor.png" />
            </div>
          </div>
          <div class=" card-body px-lg-5 py-lg-3">

            <div class="text-center">
              <a class=" btn btn-neutral btn-md btn-block btn-icon" (click)="login()">
                <span class=" btn-inner--icon">
                  <img src="assets/img/icons/logo-office.png" />
                </span>

                <span class=" btn-inner--text"> Office 365 </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>