import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Component, OnInit, ElementRef                                  } from '@angular/core';
import { NgxSpinnerService                                              } from 'ngx-spinner';
import { DomSanitizer                                                   } from '@angular/platform-browser';
import { Location                                                       } from '@angular/common';
import   swal                                                             from "sweetalert2";

import { MicrosoftAuthService } from '../../pages/auth/services/microsoft-auth.service';
import { ExpedienteService    } from 'src/app/services/expediente.service';
import { MENU_ROUTES          } from '../sidebar/constants/MenuRoutes.const';
import { Organo               } from 'src/app/models/OrganoResponse';
import { environment           } from "src/environments/environment";
import { Reply } from 'src/app/models/Reply';

@Component({
  selector    :  'app-navbar',
  templateUrl :  './navbar.component.html',
  styleUrls   : ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  sidenavOpen: boolean = true;
  organos!: Organo[];
  isLoggedIn: boolean;
  name: string;
  cityConsejo: string = '';
  photoProfile;
  entorno       : string;

  employeeId     = localStorage.getItem('employeeId');
  officeLocation = localStorage.getItem('city');

  constructor(
    private MicrosoftAuthService: MicrosoftAuthService,
    location: Location,
    public expedienteService: ExpedienteService,
    private element: ElementRef,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private _spinner: NgxSpinnerService,
  ) {
    this.isLoggedIn =
      localStorage.getItem('isLoggedIn') == 'true' ? true : false;
    this.name = localStorage.getItem('name');

    this.photo();

    this.location = location;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator

        if (window.innerWidth < 1200) {
          document.body.classList.remove('g-sidenav-pinned');
          document.body.classList.add('g-sidenav-hidden');
          this.sidenavOpen = false;
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });
  }

  ngOnInit() {
    this.entorno = environment.nombreEntorno;
    this.readOrganos();
    this.listTitles = MENU_ROUTES.filter((listTitle) => listTitle);
  }

  logout() {
    const HistorialGetDTO = {
      numberPage: 0,
      idUsuario: this.employeeId,
    }
    this.expedienteService.CreateBitacoraAccesoUsuario(HistorialGetDTO).subscribe({
      next: ({ result, message }: Reply) => {
        if (result != 1) {
          swal.fire({
            icon : 'warning',
            title: 'Atención:',
            text : message,
            confirmButtonText: 'Aceptar',
          });
        }else{
          this.MicrosoftAuthService.logout();
        }
      },
      error: (e) => {
        this.handleError(e);
      }
    });
  }

  photo() {
    this.MicrosoftAuthService.photo().subscribe((blob) => {
      var urlCreator = window.URL || window.webkitURL;
      this.photoProfile = this.domSanitizer.bypassSecurityTrustUrl(
        urlCreator.createObjectURL(blob)
      );
    });
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  openSearch() {
    document.body.classList.add('g-navbar-search-showing');
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-showing');
      document.body.classList.add('g-navbar-search-show');
    }, 150);
    setTimeout(function () {
      document.body.classList.add('g-navbar-search-shown');
    }, 300);
  }
  closeSearch() {
    document.body.classList.remove('g-navbar-search-shown');
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-show');
      document.body.classList.add('g-navbar-search-hiding');
    }, 150);
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hiding');
      document.body.classList.add('g-navbar-search-hidden');
    }, 300);
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hidden');
    }, 500);
  }
  openSidebar() {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
      this.sidenavOpen = false;
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
      this.sidenavOpen = true;
    }
  }
  toggleSidenav() {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
      this.sidenavOpen = false;
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
      this.sidenavOpen = true;
    }
  }
  readOrganos() {
    let numeroEmpleado = Number(localStorage.getItem('employeeId'));
    this.expedienteService.readAllOrganos(numeroEmpleado).subscribe({
      next: (resp) => {
        if (resp.result == 500) {
          swal.fire({
            icon: 'warning',
            title: 'Atención:',
            text: resp.message,
            confirmButtonText: "Aceptar",
          });
        }else{
          this.organos = resp.data;
          this.cityConsejo =
          this.organos[parseInt(this.officeLocation) - 1].organo;
        }
      },
      error: (e) => {
        this.handleError(e);
      },
    });
  }

  private handleError(error: any) {
    this._spinner.hide();
    let errorMessage = "No se pudo establecer conexión con el servidor, verifique su conexión.";
    if (error.status === 0) {
      errorMessage = "Vuelva a intentar por favor";
    } else if (error.status === 400) {
      errorMessage = "Se produjo uno o más errores de validación.";
    } else if (error.status === 403) {
      errorMessage = "No cuenta con permisos de acceso para realizar la consulta.";
    }

    swal.fire({
      icon: 'warning',
      title: 'Atención:',
      text: errorMessage,
      confirmButtonText: "Aceptar",
    });
  }
}
