<!-- Utiliza el ngFor para generar los elementos del submenú -->
<ul class="nav nav-sm flex-column">
    <li class="nav-item" *ngFor="let subItem of subItems">
        <!-- Si el subItem es visible, muestra el enlace del menú -->
        <a *ngIf="checkMenuVisibility(subItem) && subItem.type === 'link'" routerLinkActive="active"
            [routerLink]="[subItem.path]" class="nav-link">
            {{ subItem.title }}
        </a>
        <!-- Si el subItem es visible y tiene subItems, muestra el botón para colapsar/desplegar el submenú -->
        <a *ngIf="checkMenuVisibility(subItem) && subItem.type === 'sub'" data-toggle="collapse" class="nav-link"
            (click)="subItem.isCollapsed = !subItem.isCollapsed" [attr.aria-expanded]="!subItem.isCollapsed"
            [attr.aria-controls]="getCollapseId(subItem)">
            {{ subItem.title }}
        </a>
        <!-- Si el subItem es visible y tiene subItems, muestra el submenú recursivamente -->
        <div *ngIf="checkMenuVisibility(subItem) && subItem.type === 'sub'" [collapse]="subItem.isCollapsed"
            [isAnimated]="true" [attr.aria-controls]="getCollapseId(subItem)">
            <app-submenu [subItems]="subItem.subItems" [rolesUsuario]="rolesUsuario"></app-submenu>
        </div>
    </li>
</ul>