import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { rolesUsuario } from './pages/auth/constants/rolesUsers.constant';
import { MicrosoftLoginGuard } from './pages/auth/guards/microsoft-login.guard';
import { MicrosoftRolesGuard } from './pages/auth/guards/microsoft-roles.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/auth/auth.module').then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [MicrosoftLoginGuard],
    // Arreglo de roles de usuario permitidos
    children: [
      {
        path: 'inicio',
        canActivate: [MicrosoftRolesGuard],
        data: {
          rolUsuario: [
            rolesUsuario.SUPERADMIN,
            rolesUsuario.ADMINISTRADOR,
            rolesUsuario.CAPTURAJURIDICO,
            rolesUsuario.CAPTURACONSEJOS,
            rolesUsuario.CONSULTAJURIDICO,
            rolesUsuario.CONSULTACONSEJOS,
            rolesUsuario.OFICIALIA,
          ],
        },
        loadChildren: () =>
          import('./pages/inicio/inicio.module').then((m) => m.InicioModule),
      },
      {
        path: 'expedientes',
        // Los roles los maneje en router de expedientes
        loadChildren: () =>
          import('./pages/emision-expedientes/emision-expedientes.module').then(
            (m) => m.EmisionExpedientesModule
          ),
      },
      {
        path: 'reporeg',
        canActivate: [MicrosoftRolesGuard],
        data: {
          rolUsuario: [
            rolesUsuario.SUPERADMIN,
            rolesUsuario.ADMINISTRADOR,
            rolesUsuario.CAPTURAJURIDICO,
          ],
        },
        loadChildren: () =>
          import('./pages/reportes/reportes.module').then(
            (m) => m.ReportesModule
          ),
      },
      {
        path: 'catalogo',
        canActivate: [MicrosoftRolesGuard],
        data: {
          rolUsuario: [rolesUsuario.SUPERADMIN, rolesUsuario.ADMINISTRADOR],
        },
        loadChildren: () =>
          import('./pages/catalogos/catalogos.module').then(
            (m) => m.CatalogosModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'inicio',
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
